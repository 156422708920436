@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  * {
    margin: 0;
    padding: 0;
  }
  html {
    height: 100%;
  }
  body {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
  }
  img {
    display: block;
  }

  ul,
  ol,
  li {
    padding: 0;
    margin: 0;
  }
  input,
  button,
  textarea,
  select {
    font: inherit;
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  #root,
  #__next {
    isolation: isolate;
  }
  .smooth-transition {
    transition: all ease-in-out 0.3s;
  }

  .show-menu {
    display: flex;
    position: fixed !important;
    margin-top: 0;
    margin-right: 0;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(5px);
    left: 0;
    padding: 1rem;
    overflow-x: hidden;
    z-index: 40;
    font-weight: 600;
    color: black;
    height: 100vh;
  }

  ul.pagination {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 2rem;
  }
  .selected a {
    color: rgb(37, 99, 235);
  }
  .card-zoom {
    @apply relative flex items-center justify-center m-3 overflow-hidden  h-60 rounded-md sm:w-screen sm:h-80 md:w-56 md:h-72 lg:w-72;
  }
  .card-zoom-image {
    @apply absolute w-full h-full transition-all duration-500 ease-in-out transform shadow-sm cursor-pointer object-cover object-center;
  }
  .card-zoom:hover .card-zoom-image {
    @apply scale-150;
  }
}

.img-modal div {
  width: 70vw;
  height: 70vh;
  top: 10%;
}
.img-modal div img {
  width: 100%;
  height: 100%;
}
.nav.default-nav {
  width: 3rem;
  height: 3rem;
}

.react-slideshow-container .nav.default-nav:first-child {
  margin-left: 10px;
}

.react-slideshow-container .nav.default-nav:last-child {
  margin-right: 10px;
}

@media screen and (max-width: 640px) {
  ul.pagination {
    font-size: 1rem;
    gap: 1rem;
  }
  .img-modal div {
    width: 90vw;
    height: 60vh;
    top: 30%;
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 14rem;
  height: 14rem;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
